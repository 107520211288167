/* App.css */

/* General Body Styling */
body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  color: #333;
}

/* Container for the app */
.app-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.header {
  text-align: center;
  margin-bottom: 20px;
}

.header h1 {
  font-size: 2em;
  color: #007bff;
}

.header p {
  font-size: 1em;
  color: #666;
}

/* Loading and Error Messages */
.loading,
.error {
  text-align: center;
  font-size: 1.2em;
  color: #888;
}

/* Data Display Styling */
.data-container {
  display: flex;
  flex-direction: column;
}

.garage-item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.garage-item:last-child {
  border-bottom: none;
}

.garage-name {
  font-weight: bold;
}

.garage-vacancy {
  color: #28a745;
}

/* Responsive Design */
@media (max-width: 600px) {
  .garage-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .garage-name {
    margin-bottom: 5px;
  }
}